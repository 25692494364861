import React, { useState } from 'react'
import Dialog from '../Dialog'
import Cropper from 'react-easy-crop'
import Button from '../Buttons'
import { useTranslation } from 'react-i18next'
import Radio from '../Radio'

const ImageCropper = ({ open, closeFun, image, saveImage }) => {
    const { t } = useTranslation()
    const [crop, setCrop] = useState({
        x: 0,
        y: 0,
    })
    const [zoom, setZoom] = useState(1)
    const [finishedImage, setFinishedImage] = useState(null)
    const [size, setSize] = useState(4 / 3)

    const onCropComplete = async (croppedArea, croppedAreaPixels) => {
        try {
            const croppedImageBlob = await getCroppedImg(
                image,
                croppedAreaPixels
            )
            const croppedImageFile = new File(
                [croppedImageBlob],
                'cropped-image.jpg',
                {
                    type: 'image/jpeg',
                    lastModified: Date.now(),
                }
            )
            setFinishedImage(croppedImageFile)
        } catch (error) {
            console.error('Error cropping image:', error)
        }
    }

    // Function to get cropped image as a Blob
    const getCroppedImg = (imageSrc, pixelCrop) => {
        return new Promise((resolve, reject) => {
            const image = new Image()
            image.onload = () => {
                const canvas = document.createElement('canvas')
                const ctx = canvas.getContext('2d')

                canvas.width = pixelCrop.width
                canvas.height = pixelCrop.height

                ctx.drawImage(
                    image,
                    pixelCrop.x,
                    pixelCrop.y,
                    pixelCrop.width,
                    pixelCrop.height,
                    0,
                    0,
                    pixelCrop.width,
                    pixelCrop.height
                )

                // Extract file extension from the original image
                const fileExtension = imageSrc.split('.').pop()

                canvas.toBlob((blob) => {
                    // Create a new File object with the cropped image Blob and the original file name and extension
                    const croppedImageFile = new File(
                        [blob],
                        `cropped-image.${fileExtension}`,
                        {
                            type: `image/${fileExtension}`,
                            lastModified: Date.now(),
                        }
                    )
                    resolve(croppedImageFile)
                }, `image/${fileExtension}`)
            }

            image.onerror = (error) => {
                reject(error)
            }

            // Set the image source after setting up the onload and onerror handlers
            image.src = imageSrc
        })
    }

    const finishedCrop = () => {
        if (finishedImage) {
            saveImage({ target: { files: [finishedImage] } })
            setFinishedImage(null) // Clear finished image after saving
        }
    }

    const onCropChange = (newCrop) => {
        setCrop(newCrop) // Update the crop state with the new crop size and position
    }

    const changeSize = (event) => {
        setSize(event)
    }

    return (
        <Dialog isOpen={open} onClose={closeFun} width={700} height={500}>
            <h4>{t('cropImageLabel')}</h4>
            <div
                className="flex justify-center pt-2"
                style={{ width: '100%', height: '95%' }}
            >
                <div
                    style={{
                        width: '80%',
                        height: '65%',
                        position: 'absolute',
                    }}
                >
                    <Cropper
                        image={image}
                        crop={crop}
                        zoom={zoom}
                        aspect={size}
                        onCropChange={onCropChange}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <div className="self-end mt-4 flex flex-col gap-4 w-full">
                    <div className="w-full flex justify-center">
                        <Radio.Group onChange={changeSize}>
                            <Radio value={1 / 1}>1:1</Radio>
                            <Radio value={5 / 4}>5:4</Radio>
                            <Radio value={4 / 3}>4:3</Radio>
                            <Radio value={3 / 2}>3:2</Radio>
                            <Radio value={5 / 3}>5:3</Radio>
                            <Radio value={16 / 9}>16:9</Radio>
                            <Radio value={3 / 1}>3:1</Radio>
                        </Radio.Group>
                    </div>
                    <Button
                        block={true}
                        color="indigo-600"
                        variant="solid"
                        onClick={finishedCrop}
                    >
                        {t('saveButton')}
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default ImageCropper
