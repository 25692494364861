const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/categories',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    locale: 'uz',
    enableMock: true,
}

export default appConfig
