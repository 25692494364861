import axios from 'axios';
import useAuth from 'utils/hooks/useAuth';

function useAsistant() {
    const { signOut } = useAuth();

    // Create an instance of axios with custom configurations
    const instance = axios.create({
        baseURL: "https://backend.the-artisan.uz/api/",
        headers: {
            'Content-Type': 'application/json',
        },
    });

    // Interceptor to add Authorization header
    instance.interceptors.request.use(config => {
        const token = localStorage.getItem('admin');
        const filterToken = JSON.parse(token);
        const filterAuthToken = JSON.parse(filterToken?.auth);
        if (filterAuthToken?.session?.token) {
            config.headers.Authorization = `Bearer ${filterAuthToken?.session?.token}`;
        }
        return config;
    });

    function asistant(versionTrue, method, basicUrl, data, headers = {}) {
        const apiVersion = process.env.REACT_APP_API_VERSION;
        const url = !versionTrue
            ? basicUrl
            : `${apiVersion}${basicUrl}`;

        // Make the API call using the instance
        return instance({
            method: method.toUpperCase(),
            url: url,
            data: (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT') ? data : undefined,
            headers: { ...headers },
        })
            .then((response) => {
                // Return the response data
                return response.data;
            })
            .catch((error) => {
                // Handle errors here
                console.error('API Call Error:', error.message);
                if (error.response && error.response.status === 401) {
                    signOut();
                }
                throw error;
            });
    }
    return { asistant };
}

export default useAsistant;
